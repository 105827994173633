@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.2.3/css/bootstrap.min.css');
/***** General CSS *****/

body {
    word-break: break-word;
    font: 15px/25px 'Poppins', sans-serif;
    color: #393939;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: #28b16d;
    white-space: initial;
}

a:hover,
a:focus {
    text-decoration: none;
    color: #393939;
}

a:hover {
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
}

img {
    max-width: 100%;
}

input[type="text"]:focus,
textarea:focus,
input[type="password"]:focus,
select:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    outline: none;
}

select,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="number"],
textarea,
input[type="tel"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

ul {
    margin: 0 0 20px;
    padding: 0;
    list-style-type: none;
}

p {
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
}


/***** Font Files *****/


/***** Custom Classes *****/

.noPadding {
    padding: 0;
}

.noLeft {
    padding-left: 0;
}

.noRight {
    padding-right: 0;
}

.centerCol {
    float: none;
    margin: 0 auto;
}

.theme-btn {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 15px 40px;
    color: #fff;
    margin: 20px 20px 20px 0;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    text-transform: capitalize;
    border: 2px solid;
    text-transform: uppercase;
}

.theme-btn:hover {
    background-color: #fff;
    color: #28b16d;
    border-color: #28b16d;
}

.flexRow {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}

.flexCol {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    align-items: center;
}

h1 {
    font-family: "Rajdhani", sans-serif;
    font-size: 55px;
    line-height: 60px;
    color: #fff;
    font-weight: 500;
    margin: 0 0 17px;
}

h2 {
    font-family: "Rajdhani", sans-serif;
    font-size: 55px;
    line-height: 60px;
    color: #393939;
    font-weight: 500;
    margin: 0 0 30px;
}

h3 {
    font-family: "Rajdhani", sans-serif;
    font-size: 30px;
    line-height: 34px;
    color: #000000;
    font-weight: 600;
    margin: 0 0 28px;
}

h4 {
    font-family: "Rajdhani", sans-serif;
    font-size: 24px;
    line-height: 29px;
    color: #393939;
    font-weight: 500;
    margin: 0 0 13px;
}

h5 {
    font-family: "Rajdhani", sans-serif;
    font-size: 20px;
    line-height: 25px;
    color: #393939;
    font-weight: 500;
    margin: 0 0 20px;
}

h6 {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    line-height: 23px;
    color: #393939;
    font-weight: 500;
    margin: 0 0 22px;
}

select {
    background: #fff;
    padding: 0 40px 0 30px;

}

::-webkit-input-placeholder {
    color: #575757;
}

::-moz-placeholder {
    color: #575757;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #575757;
}

:-moz-placeholder {
    color: #575757;
    opacity: 1;
}


/*header css start */

.menuSec {
    padding: 0px 0;
    background-color: #256c42;
}

.menuSec img {
    margin: 0;
}

.menuSec ul {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    text-transform: capitalize;
}

.menuSec ul li {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0;
}

.menuSec li ul {
    display: none;
}

.menuSec ul li a {
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #393939;
    padding: 10px 23px;
    font-size: 13px;
}

.menuSec ul li:last-child a:after {
    display: none;
}

.menuSec ul li a:hover,
.menuSec ul li a.active {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    color: #28b16d;
}

.menuSec li:hover>ul {
    display: block;
    position: absolute;
    z-index: 1000;
    background-color: #000000;
    left: 0px;
    width: 230px;
    text-align: left;
    top: 38px;
}

.menuSec li>ul>li>a {
    border: none;
    padding: 13px 20px !important;
    color: #fff !important;
    overflow: hidden;
    font-size: 13px;
    line-height: 20px;
}

.menuSec li>ul>li,
.menuSec li>ul>li>a {
    display: block;
    margin: 0;
}

.menuSec li>ul>li>a:before,
.menuSec li>ul>li>a:after {
    display: none;
}

.menuSec li:hover li {
    float: none;
}

.menuSec li ul li a:hover {
    background-color: #ffffff;
    color: #000000 !important;
}

.menuSec ul ul ul {
    left: 100%;
    top: 0;
}

.menuSec ul:before,
.menuSec ul:after {
    content: " ";
    display: table;
}

.menuSec ul:after {
    clear: both;
}

.menuSec li>ul>li:hover>ul {
    left: 230px;
    top: 0px;
    width: 270px;
}

.droopdwon li:hover>ul {
    display: block;
    position: absolute;
    z-index: 1000;
    background-color: #000000;
    left: 0px;
    width: 230px;
    text-align: left;
    top: 40px;
}

.droopdwon {
    float: left;
}

.droopdwon li:hover li a:hover {
    background-color: #ffffff;
    color: #000 !important;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    background-color: #1a4a2e;
    color: white;
    padding: 15px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    -webkit-appearance: auto;
}

.dropdown-content li {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content li:hover {
    background-color: #f1f1f1;
}

.dropdown-content.show {
    display: grid !important;
    text-align: justify;
    padding: 10px;
}

.ankar ul {
    display: flex;
    align-items: center;
    gap: 70px;
    justify-content: end;
    margin-bottom: 0;
}

.ankar ul li a {
    display: flex;
    align-items: center;
    gap: 10px;
}

.ankar ul li a h5 {
    font-size: 16px;
    color: #080707;
    font-family: 'Rajdhani';
    font-weight: 600;
    margin-bottom: 0;
}

.ankar ul li a h5 span {
    display: block;
    font-size: 17px;
}

.ankar ul li a i {
    font-size: 20px;
    color: #000;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    border-radius: 50%;
    transition: 0.3s ease-in-out;
}

.ankar ul li a:hover i {
    background: #256c42;
    color: #fff;
}

.input-btn {
    position: relative;
}

.input-btn input {
    width: 100%;
    padding: 15px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
}

.input-btn i {
    color: #000;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #e1e1e1;
    font-size: 20px;
    width: 76px;
    height: 58px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topSec {
    padding: 10px 0px;
}

.topSec .row {
    align-items: center;
}

.sidenav {
    height: 100%;
    width: 20%;
    position: fixed;
    z-index: 11;
    top: 0;
    background-color: #256c42;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    right: 0;
    transform: translate(110%, 0px);
}

.sidenav a {
    position: relative;
    padding: 10px 0;
    text-decoration: none;
    font-size: 25px;
    color: #fff;
    display: block;
    text-transform: uppercase;
    transition: 0.3s;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 40px;
}

.sidenav a::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 0;
    margin: 0 auto;
    border-bottom: 2px solid #dcccb5;
    transition: ease-in;
    transition-duration: 0.2s;
}

.sidenav a:hover::before {
    width: 100%;
    transition: ease-in;
    transition-duration: 0.2s;
}

.sidenav a:hover {
    color: #111112;
    transition: ease-out;
    transition-duration: 0.2s;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    transition: ease-out;
    transition-duration: 0.2s;
}

.nav-head {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100%;
    gap: 15px;
    color: #fff;
}

/*header css start */

.dropdown-menu {
    position: absolute !important;
    z-index: 1000;
    display: block;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

header {
    position: relative;
}

.nav-head i {
    font-size: 30px;
    height: 54px;
    width: 59px;
    background-color: #1a4a2e;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

/*banner css start */

.bann-txt h3 {
    font-size: 32px;
    font-weight: 400;
    color: #111112;
    line-height: 32px;
    margin-bottom: 0;
}

section.banner {
    position: relative;
}

.head-3 {
    position: absolute;
    top: 58px;
    left: 17%;
    z-index: -1;
}

.head-3 h3 {
    font-size: 151px;
    line-height: 151px;
    color: #28282836;
}

.bann-txt h2 {
    font-size: 32px;
    color: #111112;
    line-height: 32px;
    padding-bottom: 20px;
    border-bottom: 1px solid #256c42;
    width: fit-content;
    margin-bottom: 10px;
}

.bann-txt p {
    font-size: 12px;
    color: #111112;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 30px;
}

a.btn-1 {
    background-color: #256c42;
    padding: 10px 20px;
    font-size: 13px;
    font-family: 'Rajdhani';
    color: #fff;
    font-weight: 500;
    border-radius: 6px;
}


.bann-slid .slick-list {
    overflow: unset;
}

.bann-slid {
    background-color: #c1c1c18f;
    padding: 60px 40px;
    border-radius: 10px;
    margin-top: 40px;
    position: relative;
}

.banner-img img {
    width: 100%;
}

.bann-slid .slick-slide {
    opacity: 0;
}


.bann-slid .slick-current {
    opacity: 1;
}

.bann-slid li.slick-active:before {
    position: absolute;
    content: '';
    width: 25px;
    height: 25px;
    border: 1px solid #256c42;
    left: -9px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 20px;
}


.bann-slid li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 8px;
    height: 8px;
    padding: 0;
    text-indent: -999px;
    cursor: pointer;
    background-color: #256c42;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 1;
    transition: opacity 0.6s ease;
    border-radius: 50%;
    position: relative;
    z-index: 1;
}

.bann-slid ul.slick-dots {
    display: flex !important;
    align-items: center;
    gap: 15px;
    position: absolute;
    bottom: 80px;
    left: 17%;
    margin-bottom: 0;
}

.bann-slid .slick-dots li button:before {
    display: none;
}

/*banner end*/
/*categories start*/
.cate-main-bx img {
    width: 55px;
    height: 60px;
    object-fit: contain;
    margin: 0 auto;
}

.cate-main-bx h5 {
    font-size: 12px;
    color: #0d0c0c;
    margin-top: 4px;
    margin-bottom: 0;
}

.cate-slid .slick-active {
    opacity: 1;
}

.cate-main-bx {
    background-color: #f6f6f6;
    box-shadow: 0 0px 5px #0000004a;
    /* padding: 10px; */
    text-align: center;
    /* border: 1px solid #0000004a; */
    margin: 20px 0px;
    padding: 16px 0px;
    transition: 0.3s ease-in-out;
}

.main-car-bx:hover {
    box-shadow: rgb(55 55 55 / 12%) 0px 48px 100px 0px;
}

.cate-main-bx:hover {
    box-shadow: 0 0 7px 2px #0000003b;
    background-color: #256c42;
}

.cate-slid {
    margin: 30px 0px;
    position: relative;
}

.gate-txt h3 {
    font-size: 44px;
    line-height: 44px;
    color: #0e0e0f;
}

.cate-slid li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 8px;
    height: 8px;
    padding: 0;
    text-indent: -999px;
    cursor: pointer;
    background-color: #256c42;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 1;
    transition: opacity 0.6s ease;
    border-radius: 50%;
    position: relative;
}

.cate-slid li.slick-active:before {
    position: absolute;
    content: '';
    width: 25px;
    height: 25px;
    border: 1px solid #256c42;
    left: -9px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 20px;
}

.cate-slid ul.slick-dots {
    display: flex;
    align-items: center;
    gap: 15px;
    position: absolute;
    bottom: 193px;
    left: unset;
    right: 10px;
}

.cate-slid .slick-dots li button:before {
    display: none;
}

/*categories end*/
.main-car-bx {
    padding: 10px;
    box-shadow: 0 0 2px #00000075;
    border-radius: 10px;
    transition: 0.3s ease-in-out;
    position: relative;
}

.main-car-bx ul {
    display: flex;
    margin-bottom: 10px;
}

.main-car-bx ul li a i {
    color: #eabe12;
}

.car-txt h5 {
    font-size: 13px;
    color: #0c0b0b;
    font-weight: 600;
    margin-bottom: 5px;
}

.car-txt h5 span {
    display: block;
    font-size: 13px;
    color: #acacac;
}

.check {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.check h4 {
    font-size: 13px;
    font-family: 'Montserrat';
    color: #0c0b0b;
    font-weight: 600;
    margin-bottom: 0;
}

.car-txt p {
    margin-top: 10px;
    font-size: 13px;
    color: #000;
    font-weight: 500;
    margin-bottom: 0;
}

.car-txt p span {
    color: #bbc0bd;
}

.car-txt {
    margin-top: 10px;
}

.car-txt a {
    margin: 0 auto;
    display: block;
    text-align: center;
}

.des-txt h4 {
    font-size: 36px;
    line-height: 36px;
}

section.Despired-car {
    padding: 40px 0px;
}

.car-main-bsx {
    background-color: #1f5a37;
    /* text-align: center; */
    padding: 20px 0px 0px 0px;
    border-radius: 10px;
    position: relative;
    z-index: 9;
}

.car-tas {
    text-align: center;
    margin-bottom: 50px;
}

.car-tas h6 {
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 0;
    line-height: 20px;
    margin-bottom: 10px;
}

.car-tas h3 {
    font-size: 23px;
    color: #fff;
    line-height: 30px;
}

.car-tas a {
    background-color: #fff;
    color: #205e3a;
}

.car-tas-img img {
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.car-tas-img {
    margin-top: 80px;
}

.tas-txt-bac h3 {
    font-size: 181px;
    color: #fff;
    width: 100%;
}

.tas-txt-bac {
    position: absolute;
    top: 226px;
    z-index: -1;
    width: 100%;
    left: -28px;
}

.new-good h4 {
    font-size: 36px;
    line-height: 36px;
    font-weight: 600;
    color: #000;
}

/*Despired-car end*/
/*house start*/

div#timer {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 30px;
}

span#days {
    display: block;
    /* border: solid #ff9900; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c0c0c0;
    font-weight: 700;
    font-size: 21px;
    width: 50px;
    background-color: #fff;
    padding: 10px;
    font-family: 'Rajdhani';
    font-weight: 800;
}

span#hours {
    display: block;
    /* border: solid #ff9900; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 21px;
    width: 42px;
    background-color: #fff;
    padding: 10px;
    font-family: 'Rajdhani';
    font-weight: 800;
    color: #c0c0c0;
}

span#minutes {
    display: block;
    /* border: solid #ff9900; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c0c0c0;
    font-weight: 700;
    font-size: 21px;
    width: 47px;
    background-color: #fff;
    padding: 10px;
    font-family: 'Rajdhani';
    font-weight: 800;
}

span#seconds {
    display: block;
    /* border: solid #ff9900; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 21px;
    width: 54px;
    background-color: #fff;
    padding: 10px;
    color: #c0c0c0;
    font-weight: 800;
    font-family: 'Rajdhani';
}

span.label {
    font-size: 14px;
    font-family: 'Rajdhani';
    font-weight: 700;
    text-align: center;
    display: block;
    margin-top: 5px;
}

section.dream-house {
    margin: 70px 0px;
    padding: 40px 0px;
    background-color: #f2f2f2;
}

.house-txt h4 {
    font-size: 50px;
    line-height: 50px;
    font-weight: 600;
    color: #0c0b0b;
}

.house-txt p {
    font-size: 14px;
    color: #a9a4a4;
    width: 81%;
    margin-bottom: 30px;
}

.house-sm-bx ul {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 0;
}

.house-sm-bx ul li h6 {
    font-size: 8px;
    font-weight: 700;
    color: #080707;
    line-height: 8px;
    margin-bottom: 0;
}

.house-sm-bx ul li a i {
    color: #eabe12;
    font-size: 13px;
}

.house-sm-bx ul li h4 {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat';
    color: #000;
}

.house-sm-bx ul li ul {
    margin-bottom: 0px;
    gap: 0;
}

.house-sm-bx {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    margin-top: 35px;
    transition: .3s all;
    border: 1px solid transparent;
}

.house-sm-bx:hover {
    box-shadow: inset 0 0 19px 11px #256c423b;
    border-color: #256c42;
}

.house-txt a {
    display: block;
    width: fit-content;
    margin-top: 30px;
}

/*Our Team Start*/


.applic-head h2 {
    margin-bottom: 0px;
}

.applic-head p {
    width: 40%;
}

.applic-head {
    margin-bottom: 30px;
}

.gallery-wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 70vh;
    gap: 30px;
}

section.application .item {
    flex: 1;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: flex 0.8s ease;
    position: relative !important;

    &:hover {
        flex: 7;
    }
}

section.application .item-1 {
    /* background-image: url('../images/team-1.png'); */
    position: relative;
    height: 620px;
    object-fit: cover;
}

section.application .item:before {
    content: "";
    position: absolute;
    background: linear-gradient(-20deg, #000000bf, #000000d4, transparent);
    width: 100%;
    height: 100%;
}

section.application .item-2 {
    /* background-image: url(../images/team-2.png); */
    position: relative;
    height: 620px;
    object-fit: cover;
}

section.application .item-3 {
    /* background-image: url('../images/team-3.png'); */
    position: relative;
    height: 620px;
    object-fit: cover;
}

section.application .item-4 {
    /* background-image: url('../images/team-4.png'); */
    position: relative;
    height: 620px;
    object-fit: cover;
}


section.application .numbers {
    position: absolute;
    bottom: 30px;
    left: 20px;
    z-index: 9;
}

section.application .item:hover:before {
    background: linear-gradient(-20deg, #372b2294, #372b22c7, transparent);
}

section.application .item:hover:after {
    background: #00000033;
}

section.application .numbers h2 {
    font-size: 20px;
    color: #fff;
    line-height: 25px;
    margin-bottom: 0;
}

section.application .numbers h2 span {
    display: block;
}

section.application .numbers h1 {
    font-size: 32px;
    color: #aed1e3;
    font-family: 'sui_generis_rg';
}


section.application .hover-text h5 {
    color: white;
}

section.application .hover-text p {
    color: white;
    width: 75%;
}


section.application .hover-text {
    padding: 30px;
    position: absolute;
    bottom: 0;
    z-index: 9;
}

section.application .hover-text h2 {
    font-size: 25px;
    color: #fff;
    width: 30%;
    position: relative;
}

section.application .hover-text h2:before {
    content: "";
    position: absolute;
    background: #7a699c;
    width: 55%;
    left: -30px;
    height: 2px;
    bottom: -7px;
}

section.application .item:after {
    content: "";
    position: absolute;
    background: #000000a8;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

section.application .hover-box {
    opacity: 0;
}

section.application .item.item-1:hover .hover-box {
    opacity: 1;
}

section.application .item.item-1:hover .numbers {
    opacity: 0;
}


section.application .item.item-2:hover .hover-box {
    opacity: 1;
}

section.application .item.item-2:hover .numbers {
    opacity: 0;
}

section.application .item.item-3:hover .hover-box {
    opacity: 1;
}

section.application .item.item-3:hover .numbers {
    opacity: 0;
}

section.application .item.item-4:hover .hover-box {
    opacity: 1;
}

section.application .item.item-4:hover .numbers {
    opacity: 0;
}


.application {
    padding: 0px 0 100px;
}

/*Our Team End*/
/*perfect start*/
section.perfect {
    padding: 60px 0px;
}

/* Footer Section Start */

.footerSec {
    background-color: #141415;
    padding: 80px 0 0px 0px;
}

.footerSec p {
    color: #fff;
    margin-top: 0;
    line-height: 25px;
    font-size: 14px;
    text-align: justify;
    margin-bottom: 0;
    font-family: 'Montserrat';
}

.foot-links h5 {
    color: #fff;
    text-transform: uppercase;
    font-family: 'Rajdhani';
    font-weight: 700;
    position: relative;
    font-size: 22px;
    margin-bottom: 50px;
    width: fit-content;
}

.foot-links h5:after {
    content: "";
    position: absolute;
    width: 119px;
    height: 1px;
    bottom: -70%;
    left: 0;
    transition: 1s ease-in-out;
    background-color: #fff;
}

ul.f-nav li a {
    font-size: 14px;
    color: #fff;
    line-height: 34px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-weight: 400;
}

ul.f-contact li a {
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 300;
    color: #fff;
}

ul.f-contact li {
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 300;
    color: #fff;
    line-height: 3;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
}

ul.f-contact li i {
    font-size: 16px;
    margin: 0 10px 0 0;
    font-weight: 600;
}

.f-input input {
    border: none;
    width: 100%;
    height: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0 20px;
    border-radius: 5px;
    color: #fff;
    background-color: unset;
    border: 1px solid #fff;
}

.f-input input::placeholder {
    color: #fff;
}

.f-input button {
    border: 0;
    width: 100%;
    height: 50px;
    background-color: #256c42;
    border-radius: 10px;
    border: unset;
    color: #fff;
}

.footer-contact ul {
    display: flex;
    margin-top: 20px;
    gap: 10px;
}

.footer-contact ul li:hover i {
    animation: scale-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
    background: #0b1e2f;
    box-shadow: 0 0 8px 4px #78b921;
}

@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

.footer-contact ul li i {
    background-color: #adc941;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 30px;
    height: 30px;
    color: #fff;
    border-radius: 0;
    transition: 0.5s ease-in-out;
}

.copy-txt p {
    font-size: 15px;
    font-family: 'Montserrat';
    font-weight: 300;
    text-align: center;
    margin: 0;
    text-transform: capitalize;
}

.footer-btm .container {
    margin-top: 70px;
    padding: 20px 0;
    /* background-image: -webkit-linear-gradient( 90deg, rgb(1, 103, 1) 0%, rgb(44, 152, 14) 100%); */
    margin: 50px auto 0;
    z-index: 1;
    position: relative;
    border-radius: 5px;
}

ul.f-contact li a {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.footer-logo {
    margin-bottom: 30px;
}

.footer-contact {
    width: 90%;
}

ul.f-contact {
    width: 90%;
}

ul.f-nav li {
    position: relative;
    z-index: 9;
    width: fit-content;
}

ul.f-nav li:after {
    position: absolute;
    content: "";
    width: 0;
    height: 10px;
    left: 0;
    z-index: -1;
    background-color: #256c42;
    transition: 0.3s ease-in-out;
    right: 0;
    margin: 0 auto;
    bottom: 6px;
}

ul.f-nav li:hover:after {
    width: 100%;
}

.foot-links:hover h5:after {
    width: 100%;
}

.footer-btm {
    position: relative;
    z-index: 1;
    background-color: #256c42;
}

.footer-btm:after {
    position: absolute;
    /* content: ""; */
    width: 100%;
    height: 1px;
    background: #fff;
    left: 0;
    bottom: 50%;
    z-index: -1;
}


/* Footer Section End */

.header-category ul li a {
    color: #fff;
    font-size: 20px;
    font-family: 'Rajdhani';
    font-weight: 600;
    background: #1a4a2e;
    padding: 14px 50px;
    display: flex;
    align-items: center;
    gap: 10px;
}



.prd_mega_menu {
    position: absolute;
    width: 100%;
    top: 130px;
    background-color: #fff;
    padding: 20px 70px;
    z-index: 9;
    border: 2px solid #fff;
    transition: 0.5s ease-in-out;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    box-shadow: 0px 12px 20px 0 #0000004f;
    display: none;
}

.mega_menu.active,
.prd_mega_menu.active,
.stry_mega_menu.active {
    top: 129px;
    display: block;
}

.mega_menu ul,
.prd_mega_menu ul {
    width: 40%;
}

.mega_menu ul li a,
.prd_mega_menu ul li a,
.stry_mega_menu ul li a {
    color: #000;
    font-size: 23px;
    font-family: "DIN-alternate-bold";
    margin-bottom: 20px;
    display: block;
    line-height: 1.2;
    transition: 0.5s;
}

.menu-sd-cont {
    width: 60%;
}

.tab-cnt {
    display: none;
    text-align: right;
}

.menu-sd-cont {
    width: 100%;
    padding: 10px;
    border-left: 1px solid #000;
    margin-right: 0;
    height: 100%;
    margin-top: 0;
    /* float: right; */
    text-align: center;
    margin: auto;
    padding-left: 60px;
}

.menu-sd-cont img {
    display: block;
    width: 100%;
    margin: 0 0 0 auto;
    height: 360px;
    object-fit: cover;
}

.tab-cnt h3 {
    font-size: 18px;
    margin: 0;
}

.tab-cnt p {
    font-size: 14px;
}

.new-man .dropdown-menu li {
    margin: 10px 0;
    width: 100%;
}

.new-man .dropdown-menu {
    right: auto;
    top: 40px !important;
    border: 1px solid #ddd;
    padding: 0;
    max-width: 600px !important;
    width: 600px !important;
    left: -80px !important;
    display: none;
}

.navbr .dropdown-littlebig {
    min-width: 13rem;
}

.inner_list {
    width: 40%;
    text-align: left !important;
    position: unset !important;
    float: left;
    border-right: 1px solid #ddd;
    margin-right: 20px !important;
    padding: 20px 0 !important;
}

.new-man:hover .dropdown-littlebig {
    display: block;
}

.tab-cnt.active {
    display: block;
    text-align: right;
}

.menu-sli-txt {
    border: 10px solid #604073;
    margin: 0 auto 20px;
    padding: 20px;
}

.menu-sli-txt h2 {
    font-size: 40px;
    line-height: 55px;
    margin: 0 0 10px;
    font-weight: 600;
}

.menu-sli-txt h4 {
    text-transform: uppercase;
    color: #604073;
    font-weight: 700;
    font-size: 30px;
}

.menu-sli-txt h5 {
    margin: 0;
    font-size: 22px;
}

.nav-underlist h5 {
    font-size: 22px;
    font-weight: 600;
    font-family: 'Rajdhani';
    text-decoration: underline;
}

.nav-underlist a {
    color: #4c4c4c;
    display: block;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 10px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Montserrat';
}

p.newsletter-message {
    text-align: center;
    margin-top: 10px;
}

.nav-underlist a:hover {
    color: #000;
}

.shape-icon ul {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.shape-icon ul li {
    width: 18%;
    text-align: center;
}

.shape-icon ul li img {
    background: #b9b9b9;
    object-fit: fill;
    padding: 30px;
    margin: 0 0 10px;
}

.shape-icon ul li h6 {
    font-size: 18px;
    color: #000;
}

.size-number h3 {
    font-size: 30px;
    line-height: 40px;
    font-family: "salvager";
}

.size-number ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.size-number ul li a {
    font-size: 16px;
    border: 1px solid #000;
    padding: 10px;
    border-radius: 30px;
    font-family: "Roboto-Medium";
}

.shape-icon h3 {
    font-size: 30px;
    line-height: 40px;
    font-family: "salvager";
}

.prod-mega-slider .slick-next:before {
    content: "\f105" !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #604073;
    border-radius: 50%;
    font-size: 16px;
    color: #fff !important;
}

.prod-mega-slider .slick-prev:before {
    content: "\f104" !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #604073;
    border-radius: 50%;
    font-size: 16px;
    color: #fff !important;
}

.prod-mega-slider .slick-next:after {
    display: none;
}

.prod-mega-slider .slick-prev:after {
    display: none;
}

a.rdo-active {
    background: rebeccapurple !important;
    color: white !important;
}

.rdbtns {
    display: flex;
    align-items: center;
    gap: 20px;
}

.rdbtns input {
    width: 20px !important;
    height: 20px !important;
    margin-bottom: 0 !important;
}

.rdbtns label {
    display: flex !important;
    /* width: fit-content !important; */
    align-items: center;
    gap: 5px;
}

.rdbtns>div {
    display: flex;
    align-items: center;
    gap: 25px;
}

p.extclient {
    margin-bottom: 0;
}

.rdbtns {
    margin-bottom: 25px;
}

/* Accessories Sec css Starts */

.accessories-txt {
    background-image: url(/public/images/accessories1.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 390px;
    width: 100%;
    padding: 80px 50px 0 410px;
    border-radius: 10px;
    border: 1px solid #00000033;
    margin: 0 0 20px 0;
}

.accessories-txt h3 {
    font-size: 53px;
    line-height: 48px;
    text-transform: uppercase;
    margin: 10px 0;
}

.accessories-txt-bottom ul {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    margin: 20px 0;
}

.accessories-txt-bottom ul li {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    height: 30px;
    padding: 0 10px;
    border: 1px solid #0000003b;
    border-radius: 5px;
}

.accessories-txt-bottom ul li h5 {
    font-size: 11px;
    line-height: 24px;
    margin: 0;
}

.accessories-card {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 #00000021;
    transition: 0.3s ease-in-out;
}

.accessories-card:hover {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.accessories-card-txt h5 {
    font-size: 14px;
    line-height: 20px;
    color: #000;
    margin: 0;
    text-transform: uppercase;
}

.accessories-card-txt p {
    font-size: 10px;
    line-height: 20px;
    margin: 0;
    text-transform: uppercase;
}

.accessories-card-txt {
    padding: 20px 0;
}

.accessories-card-img img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    width: 90px;
    object-fit: contain;
}

.accessories-product-txt h5 {
    font-size: 13px;
    line-height: 21px;
    color: #000;
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;
}

.accessories-product-txt p {
    font-size: 13px;
    line-height: 21px;
    color: #c7c7c7;
    margin: 0;
    text-transform: uppercase;
}

.accessories-star ul {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 2px;
}

.accessories-star ul li a {
    color: #eabe12;
}

.accessories-status ul li .accessories-status-txt h5 {
    font-size: 13px;
    line-height: 21px;
    text-transform: uppercase;
    font-weight: 600;
}

.accessories-status ul {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
    margin: 10px 0;
}

.accessories-bottom h6 {
    font-size: 13px;
    line-height: 21px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 10px 0 0 0;
}

.accessories-bottom h6 span {
    color: #c5c5c5;
    font-weight: 400;
}

.accessories-star {
    margin: 10px 0;
}

.accessories-product-main {
    padding: 10px;
    border: 1px solid #00000045;
    border-radius: 10px;
}

.accessories-product-txt {
    padding: 20px 0 0 0;
}

section.accessories-sec.all-section {
    padding: 100px 0px;
}

.accessories-product-img img {
    height: 324px;
    border-radius: 11px;
}

/* Accessories Sec css Ends */
/*unlock start*/
section.unlock {
    padding: 13% 0px;
    background-image: url(/public/images/unlock.jpg);
    width: 100%;
    background-repeat: no-repeat;
    /* background-size: cover; */
    position: relative;
    height: 600px;
    margin-bottom: 60px;
}

.unlock-form {
    position: absolute;
    width: 14%;
    padding: 10px;
    background-color: #fff;
    bottom: 55px;
}

.unlock-form img {
    width: 100%;
}

.unlock-txt h5 {
    font-size: 10px;
    line-height: 15px;
    font-weight: 800;
    text-align: center;
    margin: 12px 0px;
}

.unlock-txt input {
    width: 100%;
    padding: 10px 0px;
}

.unlock-txt textarea {
    width: 100%;
    resize: none;
    height: 64px;
    margin-bottom: 10px;
}

.unlock-txt button {
    margin: 0 auto;
    display: block;
    padding: 10px;
    background-color: #256c42;
    color: #fff;
    font-size: 10px;
    border: unset;
}

.unlock-2 {
    background-color: #fff;
    padding: 10px;
    position: absolute;
    width: 10%;
    left: 31%;
    bottom: 290px;
}

.unlock-3 {
    background-color: #fff;
    padding: 10px;
    position: absolute;
    width: 10%;
    left: 44%;
    bottom: 122px;
}

.unlock-3 img {
    width: 100%;
}

.unlock-3 p {
    font-size: 6px;
}

.unlock-2 p {
    font-size: 6px;
}

.unlock-2 img {
    width: 100%;
}

.unlock-txt-box h4 {
    font-size: 41px;
    line-height: 41px;
    color: #fff;
    font-weight: 600;
}

.unlock-txt-box h4 span {
    display: block;
}

.unlock-txt-box p {
    font-size: 14px;
    color: #fff;
}

.unlock-txt-box {
    background-color: #256c42;
    padding: 60px 26px;
}

/*unlock end*/
a.btn-1:hover {
    background-color: #000;
    box-shadow: inset 200px 0 0 0 #000;
    transition: .5s;
}

.cate-main-bx:hover img {
    filter: invert(1) brightness(6.5);
}

.cate-main-bx:hover h5 {
    color: #fff;
}

.copy-txt a {
    color: #fff;
    font-size: 14px;
}

/*iner pages start*/
section.banner.inn h2 {
    font-size: 44px;
}

/* account*/

.signin_sec {
    padding: 50px 0px;
    position: relative;
}

.input-icons i {
    position: absolute;
    font-size: 20px;
    z-index: 99;
    margin-top: 5px;
}

.input-icons {
    width: 100%;
}

.icon {
    padding-top: 15px;
    padding-left: 12px;
    min-width: 40px;
}

.input-field {
    position: relative;
    display: flex;
    width: 100%;
    padding-left: 45px;
    margin-bottom: 30px;
    border: none;
    padding: 10px 40px;
    border-radius: 0px;
    background-color: #ebebeb;
    height: 60px;
}

.social_btn h3 {
    font-size: 48px;
    line-height: 50px;
}

.signup_form button {
    border: none;
    width: 100%;
}

.social_btn a i {
    border-right: 1px solid #fff;
    padding-right: 5px;
}

.social_btn a {
    padding: 10px 20px;
    color: #fff;
}

.social_btn {
    margin: 20px auto;
    text-align: center;
}

.social_btn a:first-child {
    background-color: #4267B2;
}

.social_btn a:last-child {
    background-color: #02abee;
}

.login {
    /* background-color: #d7d7d770; */
    padding: 130px 50px;
}

.login h3 {
    color: #000000;
    font-size: 36px;
    text-align: center;
    font-weight: 700;
}

.login_form input {
    background-color: #d7d7d770;
    border-radius: 0px;
}

.login_form .remeber {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.login_form button {
    border: none;
    height: 50px;
}

.forgot_pass {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login_form label,
.forgot_pass a {
    font-size: 20px;
    color: #000;
    font-weight: 500;
}

.btn-2 {
    height: 60px;
    padding: 0 40px;
    color: #fff;
    font-weight: 600;
    font-size: 19px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #256c42;
    border-radius: 10px;
    font-family: 'Montserrat';
    text-transform: uppercase;
}

/* section.signin_sec:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 50%;
    background: url(../images/bg-splash.jpg);
    right: 0;
    z-index: -1;
    opacity: 0.05;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: left;
    background-size: cover;
    top: 0;
} */

.bg-from-clr {
    background: #e9e9e96b;
    padding-left: 20px;
}

.bg-from-clr .col-lg-5 {
    padding-right: 0;
}


/* account */
section.terms {
    padding: 80px 0px;
}

.term-txt h4 {
    font-size: 28px;
    line-height: 28px;
    position: relative;
    font-weight: 600;
}

.term-txt h4::before {
    position: absolute;
    content: '';
    width: 80%;
    right: 0;
    height: 1px;
    background-color: #00000078;
    top: 12px;
}

.term-txt p {
    font-size: 14px;
    color: #7d7d7d;
}



.imgArea {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    padding: 15px;
    height: 270px;
    width: 266px;
    /* background-color:rgb(242, 242, 242); */
    border-radius: 10px;
    margin: 0 auto;
}

.imgArea i {
    font-size: 100px;
}

.imgArea h4 {
    font-weight: 900;
}

.imgArea p {
    color: grey;
}

.imgArea p span {
    font-weight: 800;
}

.imgArea img {
    position: absolute;
    top: 0;
    left: 0;
    width: 66%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: 10px;
    z-index: 3;
    right: 0;
    margin: 0 auto;
}

.imgArea::before {
    content: attr(data-title);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    pointer-events: none;
    opacity: 0;
    transition: .5s;
}

.imgArea.active:hover::before {
    opacity: 1;
}

.selectImage {
    margin-top: 10px;
    padding: 10px 15px;
    border-radius: 10px;
    width: 100%;
    background-color: #ffff;
    border: unset;
    box-shadow: 0 0 10px #00000021;
    height: 295px;
}

.agreement {
    padding: 15px 15px 30px;
    margin: 15px 0 20px;
}

section.advertisement {
    padding: 80px 0px;
}

.sec-txt h3 {
    font-size: 30px;
    line-height: 30px;
    color: #000;
    margin-bottom: 15px;
}

.sec-txt h4 {
    font-size: 22px;
    line-height: 22px;
    color: #000;
}

.sec-txt p {
    font-size: 15px;
    color: #7d7d7d;
}

/* Screenshoot Upload Box */
.sec-txt form input {
    width: 100%;
    padding: 15px;
    border: 1px solid #cbcbcb;
    margin-bottom: 20px;
    border-radius: 5px;
    outline: none;
}

.sec-txt form textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid #cbcbcb;
    margin-bottom: 20px;
    border-radius: 5px;
    outline: none;
    resize: none;
    height: 150px;
}

.sec-txt form select {
    width: 100%;
    padding: 15px;
    border: 1px solid #cbcbcb;
    margin-bottom: 20px;
    border-radius: 5px;
    outline: none;
}

.sec-txt button {
    width: auto;
    padding: 12px;
    background-color: #256c42;
    border: unset;
    color: #fff;
    border-radius: 10px;
}

/* Contact Page Start */

.contact-sec {
    position: relative;
    padding: 100px 0px;
}

.contact-box {
    background: #f0f0f0;
    padding: 40px;
}

.contact-text h6 {
    font-size: 14px;
    text-transform: uppercase;
    color: #6e6e6e;
    font-family: 'Montserrat';
}

.contact-text h3 {
    font-size: 40px;
    text-transform: uppercase;
    color: #000;
    word-spacing: 10px;
    line-height: 34px;
    margin: 15px 0 10px;
}

.cont-input input {
    width: 100%;
    height: 50px;
    background: transparent;
    border: 1px solid #d0d0d0;
    padding: 10px 20px;
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
}

.cont-input input::placeholder {
    color: #000;
}

.cont-input textarea {
    width: 100%;
    background: transparent;
    padding: 10px 20px;
    color: #000;
    font-weight: 600;
    height: 100px;
    border: 1px solid #d0d0d0;
}

.cont-input textarea::placeholder {
    color: #000;
}

.contact-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: -35px 0 0 -60px;
}

ul.contact-icons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

ul.contact-icons img {
    width: 60px;
    height: 50px;
    object-fit: contain;
}

.contact-form {
    margin-bottom: 20px;
}

ul.contact-icons.center-box {
    position: relative;
    width: 90%;
    margin: 0 auto;
}

ul.contact-icons.center-box:after {
    position: absolute;
    content: '';
    width: 1px;
    height: 100%;
    left: -6%;
    top: 0;
    background: #959595;
}

ul.contact-icons.center-box:before {
    position: absolute;
    content: '';
    width: 1px;
    height: 100%;
    right: -1%;
    top: 0;
    background: #959595;
}

ul.contact-icons {
    padding: 30px 0;
}

.contact-map iframe {
    width: 100%;
    filter: invert(1);
}

.contact-map {
    margin-top: -15%;
    z-index: -1;
}

.contact-text p {
    color: #777777;
    padding: 15px 0;
    font-size: 15px;
}

ul.contact-icons p {
    color: #989897;
    font-size: 14px;
}

.contact-button button {
    background-color: #256c42;
    color: #fff;
    padding: 10px;
    border: unset;
    border-radius: 10px;
}

/* Contact Page End */
/* Product Page Start */

.product-inner-sec {
    position: relative;
    padding: 80px 0;
    background-color: #f8f8f8;
    margin-top: 70px;
}

.product-inner-sec .product-box {
    width: 100%;
    padding: 10px;
}

.product-inner-sec .size input[type="radio"]+label {
    padding: 9px 10px;
    font-size: 13px;
}

.product-inner-sec .product-side-bar h4 {
    font-size: 28px;
    margin: 30px 0 10px;
    color: #000000;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    width: 90%;
    cursor: pointer;
}

.product-inner-sec .product-side-bar ul li {
    margin: 20px 0;
    border-bottom: 1px solid #9898986e;
    padding: 10px 0;
}

.product-inner-sec .product-side-bar ul li a input {
    height: 15px;
    width: 15px;
    border: 2px solid #000;
    margin-right: 30px;
}

.product-inner-sec .product-side-bar ul li a label {
    color: #000000;
    font-size: 15px;
    font-weight: 500;
    gap: 30px;
}

.product-inner-sec ::-webkit-scrollbar {
    width: 10px;
}


/* Track */

.product-inner-sec ::-webkit-scrollbar-track {
    background: #f0f0f0;
}


/* Handle */

.product-inner-sec ::-webkit-scrollbar-thumb {
    background: #0098db;
    border-radius: 5px;
}

.product-inner-sec select {
    width: 100%;
    padding: 10px 15px;
    height: 50px;
    background-color: transparent;
    color: #000;
    border-radius: 0;
    border: 1px solid #000;
    font-size: 18px;
    /* border-radius: 50px; */
    -webkit-appearance: auto;
}

.product-inner-sec h6 {
    margin: 0;
    font-size: 18px;
    float: right;
    color: #000;
    font-family: 'Montserrat';
}

.product-inner-sec .al-cent {
    align-items: center;
}

.product-inner-sec .load-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60%;
    color: #fff;
    font-size: 25px;
    text-transform: capitalize;
    margin: 50px auto 0;
}

.product-inner-sec .product-b .prod-img {
    background: #fff;
}

.product-img img {
    width: 100%;
    height: 350px;
    margin-top: 0;
    border: 1px solid #000;
    object-fit: cover;
}

img.pr1 {
    position: relative;
    left: 0;
    top: 0;
    opacity: 1;
}

img.pr2 {
    position: relative;
    right: 0;
    top: 0;
}

.arrival-box.prd-pg-box {
    margin: 20px 0;
    border-radius: 20px;
    padding: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
    text-align: center;
}

.arrival-box.prd-pg-box img {
    height: 100%;
    object-fit: cover;
    width: auto !important;
    margin: 0 auto;
    text-align: center;
    display: flex;
}

.arrival-box.prd-pg-box i {
    color: #0098db;
}

.arrival-imag {
    height: 250px;
}

.arrival-text h4 {
    font-size: 26px;
    color: #071a48;
    text-transform: capitalize;
}

.arrival-text h3 {
    font-size: 26px;
    color: #016dff;
    padding: 20px 0;
}

.product-cart i {
    font-size: 30px;
    color: #fff !important;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #02b5c6;
    border-radius: 60px;
}

.product-cart {
    margin-bottom: -15%;
}

img.prd-4 {
    position: absolute;
    right: 0;
    top: 0;
}

img.prd-7 {
    position: absolute;
    top: 1%;
    right: 30%;
}

img.prd-6 {
    position: absolute;
    right: -5%;
    bottom: 5%;
}

img.prd-5 {
    top: 50%;
    left: -5%;
    position: absolute;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

.page-link {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: 'Exo 2';
    color: #000;
    border: 1px solid #a8957d;
    border-radius: 50px !important;
}

.page-item.active .page-link {
    background: #08863c !important;
    border: 1px solid #08863c;
}

.page-link:hover {
    background: #08863c;
    color: #fff;
}

.product-inner-sec .product-side-bar ul li {
    margin: 20px 0;
    border-bottom: 0;
    padding: 10px 0;
}

.car-img img {
    width: 100%;
    height: 185px;
    object-fit: cover;
    border-radius: 5px;
}

.product-inner-sec .main-car-bx {
    margin-bottom: 30px;
}

/* Product Page End */
.contact-text a {
    font-size: 30px;
    line-height: 30px;
    color: #256c42;
    font-weight: 600;
    font-family: 'Rajdhani';
}

.uplod-sm-img {
    margin-top: 30px;
}

.uplod-sm-img ul {
    display: flex;
    align-items: center;
    gap: 15px;
}


.uplod-sm-img ul li {
    box-shadow: 0 0 6px #00000026;
    padding: 25px;
    border-radius: 10px;
}

section.product-inner-sec.all-section .row {
    align-items: end;
}

.btn-primary {
    color: #fff;
    background-color: #256c42;
    padding: 13px;
}

.product-inner-sec select {
    background-color: #fff;
    border-radius: 100px;
    border: unset;
    box-shadow: 0 0 10px #256c4291;
}

.btn-primary:hover {
    background-color: #000;
    border: unset;
}

.slick-track {
    display: flex;
    align-items: center;
}


.slick-dots>li>button {
    font-size: 0px;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: transparent;
    padding: 5px;
    border: 0;
    outline: none;
    background: transparent;
}

.cate-slid .slick-slide {
    margin: 0 10px;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

section.categories {
    padding-top: 60px;
}

.cate-slid ul.slick-dots {
    display: flex ! IMPORTANT;
    align-items: center;
    margin: 0;
}

header .input-btn {
    width: 100%;
    margin: 0 auto;
}

.header-category ul li a:hover {
    color: #fff;
}

body.sidenav-open {
    position: relative;
}

body.sidenav-open:after {
    position: absolute;
    content: "";
    background: #00000069;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    backdrop-filter: blur(10px);
}

header .input-btn button {
    padding: 0;
    border: 0;
}

.cate-main-bxinn {
    background-color: #fff;
    padding: 10px 16px;
}

.cate-main-bxinn h4 {
    background-color: #256c42;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    padding: 15px;
}

.cate-main-bxinn p {
    font-weight: 700;
    color: #000;
    margin: 0 0 5px;
}

.cate-main-bxinn ul li a {
    color: #28363e;
    display: block;
    font-size: 13px;
    line-height: 18px;
    margin: 0 14px 3px;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 2px solid #256c4200;
    font-family: 'Montserrat';
    display: inline-block;
}

.cate-main-bxinn ul li a.selected {
    border-bottom: 2px solid #256c42;
    color: #256c42;
}

.cate-main-bxinn ul li a span {
    color: #256c42;
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
}

.loc input {
    width: 100%;
    padding: 10px;
    background-color: #fff;
    margin: 20px 0px;
    border: 1px solid #256c42;
    border-radius: 4px;
    height: 48px;
    outline: none;
    color: #256c42;
}

.loc input::placeholder {
    color: #256c42;
}

.discount-txt ul li input {
    border: 2px solid #6c8ea0;
    border-radius: 50%;
    cursor: pointer;
    height: 16px;
    min-height: 16px;
    min-width: 16px;
    position: relative;
    width: 16px;
}

.discount-txt ul li {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 12px;
}

.price-in {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.price-in input {
    border: 1px solid #256c42;
    border-radius: 4px;
    height: 48px;
    max-width: 124px;
    padding: 6px 8px;
    margin-bottom: 30px;
    outline: none;
    color: #256c42;
}

.price-in input::placeholder {
    color: #256c42;
    text-transform: capitalize;
}

.price-renge ul li {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 15px;
}

.price-renge ul li input {
    border: 2px solid #6c8ea0;
    border-radius: 50%;
    cursor: pointer;
    height: 16px;
    min-height: 16px;
    min-width: 16px;
    position: relative;
    width: 16px;
}

.price-renge ul li p {
    color: #28363e;
    cursor: pointer;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 0;
}

/* Product Details Area CSS
============================================================================================ */

.product_details_area {
    /* Styles for the product details area */
    padding: 80px 0px;
}

.product_details_inner {
    /* Inner container styles */
}

.product_details_left .price_title {
    border-bottom: 1px solid #e5e5e5;
}

.product_details_left .price_title .left {
    display: flex;
    align-items: center;
}

.product_details_left .price_title .left h3 {
    color: var(--dip);
    font: bold 36px var(--bar);
    margin-bottom: 20px;
    padding-right: 14px;
    font-weight: 800;
    font-size: 40px;
    color: #000;
}

.product_details_left .price_title .left .review_link {
    font-size: 14px;
    color: #777777;
    margin-left: 10px;
    margin-bottom: 15px;
}

.product_details_left .price_title .right h4 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
    color: #256c42;
}

.product_details_left .product_info {
    padding: 18px 0;
}

.product_details_left .product_info a {
    background: #f5f7f7;
    display: inline-block;
    padding: 0 13px;
    font: normal 16px / 40px var(--bar);
    color: var(--pfont);
    margin-right: 21.5px;
}

.product_details_left .product_info a:last-child {
    margin-right: 0;
}

.product_details_left .product_info a i {
    padding-right: 10px;
    font-size: 14px;
}

.product_details_left .product_info a:hover {
    /* color: #fff; */
}

.product_d_slider .product_main_slider .item {
    display: block;
}

.product_d_slider .product_nav_slider {
    padding-top: 20px;
    margin: 0 -10px;
}

.product_d_slider .product_nav_slider .item {
    display: block;
    padding: 0 10px;
}

.product_d_slider .product_nav_slider .item .img_inner {
    position: relative;
    cursor: pointer;
}

.product_d_slider .product_nav_slider .item .img_inner:before {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s;
}

.product_d_slider .product_nav_slider .item img {
    width: 100%;
}

.product_d_slider .product_nav_slider .item.slick-current .img_inner:before {
    opacity: 1;
}

.product_overview_text {
    margin-top: 70px;
}

.product_overview_text h4 {
    color: var(--dip);
    font: bold 36px var(--bar);
    margin-bottom: 16px;
}

.product_overview_text p {
    margin-bottom: 25px;
}

.product_overview_text .nav li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 25px;
    color: var(--pfont);
    display: block;
    cursor: auto;
}

.product_overview_text .nav li img {
    position: absolute;
    left: 0;
    top: 5px;
}

.product_overview_text .nav li:last-child {
    margin-bottom: 0;
}

.specification_area {
    margin-top: 60px;
}

.specification_area .single_b_title {
    margin-bottom: 35px;
}

.specification_area .single_b_title h3 {
    margin-bottom: 0;
    font-size: 36px;
}

.specification_area .left_spec {
    margin-right: 30px;
    height: 100%;
}

.specification_area .left_spec .nav {
    border: none;
    background: #f5f7f7;
    height: 100%;
    padding: 25px 0;
}

.specification_area .left_spec .nav a {
    border: none;
    background: #f5f7f7;
    display: block;
    font: normal 16px / 60px var(--bar);
    padding: 0 30px;
    color: var(--pfont);
    position: relative;
}

.specification_area .left_spec .nav a:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 0 30px 20px;
    border-color: transparent transparent transparent transparent;
    position: absolute;
    right: -20px;
    top: 0;
    opacity: 0;
    transition: opacity 0.3s;
}

.specification_area .left_spec .nav a:hover,
.specification_area .left_spec .nav a.active {
    color: #fff;
}

.specification_area .left_spec .nav a:hover:before,
.specification_area .left_spec .nav a.active:before {
    opacity: 1;
}

.specification_area .right_spec {
    margin-left: -30px;
}

.specification_area .right_spec .tab-pane {
    padding: 0;
}

.specification_area .right_spec .tab-pane .spec_information {
    max-height: auto;
    background: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
    padding: 30px;
}

.specification_area .right_spec .tab-pane .spec_information h4 {
    color: var(--dip);
    font: bold 20px var(--bar);
}

.specification_area .right_spec .tab-pane .spec_information .nav li {
    border-bottom: 1px solid #dddddd;
    line-height: 60px;
}

.specification_area .right_spec .tab-pane .spec_information .nav li span {
    float: right;
    display: inline-block;
    color: #000;
    font-weight: 800;
}

.specification_area .right_spec .tab-pane .spec_information .nav li img {
    float: right;
    margin-top: 22px;
}

.specification_area .right_spec .tab-pane .spec_information .nav li:last-child {
    border-bottom: 0;
}

.related_cars_area .related_cars_inner {
    margin-bottom: -30px;
}

.product_list_right .main_btn {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    text-transform: uppercase;
    line-height: 58px;
}

.product_list_right .main_btn+.main_btn {
    background: #34373b;
    border-color: #34373b;
}

.product_list_right .main_btn+.main_btn:hover {
    color: #fff;
}

.product_list_right .nav {
    background: #f5f7f7;
    padding: 3px 30px;
}

.product_list_right .nav li a {
    line-height: 60px;
    display: block;
    border-bottom: 1px solid #dddddd;
    color: var(--pfont);
    cursor: auto;
}

.product_list_right .nav li a span {
    float: right;
    color: var(--dip);
    font-weight: bold;
}

.product_list_right .nav li a span.color {
    height: 23px;
    width: 23px;
    background: var(--baseColor);
    border-radius: 50%;
    margin-top: 19px;
}

.product_list_right .nav li a i {
    padding-right: 10px;
}

.product_list_right .nav li:last-child a {
    border: none;
}

.product_nav_slider .slick-active {
    opacity: 1;
}

.pr_rate {
    background: #f5f7f7;
    padding: 40px;
    display: flex;
}

.pr_rate .media-left {
    border-right: 1px solid #dcdede;
    padding-right: 30px;
}

.pr_rate .media-body .text {
    flex: 1;
    padding-right: 80px;
}

.pr_rate .media-body p {
    font-size: 16px;
    line-height: 28px;
    color: #777777;
    margin-bottom: 0;
}

.pr_rate .media-body h3 {
    color: #34373b;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}

.pr_rate .media-left span {
    font-size: 44px;
    color: #34373b;
    font-weight: 700;
    margin-bottom: 10px;
    display: inline-block;
}

a.main_btn.red.popup-with-zoom-anim {
    padding: 12px 25px;
    background-color: #256c42;
    font-size: 15px;
    color: #fff;
    border-radius: 10px;
    margin-left: 10px;
}

.media-left i {
    color: #f6cc38;
    margin: 15px 0px;
}

.media-body {
    display: flex;
    align-items: baseline;
    padding-left: 18px;
}

.media-body {
    display: flex;
    align-items: baseline;
    padding-left: 18px;
}

.product_d_slider .product_nav_slider .item {
    display: block !important;
    padding: 0px 10px;
}

.product_d_slider .slick-slide {
    margin: 0px 0px;
}

/* End Product Details Area CSS
  ============================================================================================ */

.pr_review_info {
    background-color: white;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
    padding: 40px;
    margin-top: 40px;
}

.pr_review_item h5 {
    font-size: 16px;
    font-weight: 600;
    color: #34373b;
}

.pr_review_item p {
    font-size: 15px;
    line-height: 29px;
}

.pr_review_item .like {
    font-size: 14px;
    color: #777777;
    vertical-align: middle;
    margin-top: 20px;
    display: inline-block;
}

.pr_footer ul {
    margin-bottom: 10px;
}

.pr_footer ul li {
    display: inline-block;
}

.pr_footer ul li .page-numbers {
    font-size: 16px;
    color: #bdbebf;
    border: 1px solid #bdbebf;
    text-align: center;
    border-radius: 2px;
    line-height: 42px;
    display: inline-block;
    padding: 0px 12px;
}

.row.pr_footer p {
    margin: 0;
}

.product_d_slider .product_main_slider .item>img {
    width: 100%;
    height: 435px;
    object-fit: cover;
}

.product_overview_text h4 {
    color: #34373b;
    font-size: 35px;
    line-height: 35px;
    font-weight: 600;
}

.specification_area .left_spec .nav a {
    border: none;
    background: #f5f7f7;
    display: block;
    font: normal 16px / 60px "Barlow", sans-serif;
    padding: 0px;
    color: #777777;
    padding: 0px 30px;
    border-radius: 0px;
    position: relative;
}

.specification_area .left_spec .nav a:hover {
    background: #256c42 !important;
}

.specification_area .left_spec .nav a.active {
    color: #fff;
    background: #256c42 !important;
}

div#review {
    margin-top: 50px;
}

section.advertisement {
    padding: 80px 0px;
}

/*steps form start*/
#svg_form_time {
    height: 15px;
    max-width: 80%;
    margin: 40px auto 20px;
    display: block;
}

#svg_form_time circle,
#svg_form_time rect {
    fill: white;
}

.button {
    background: rgb(37 108 66);
    border-radius: 5px;
    padding: 15px 25px;
    display: inline-block;
    margin: 10px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgb(0, 0, 0, 0.5);
}

.disabled {
    display: none;
}

section.stepsss {
    padding: 50px;
    max-width: 1340px;
    margin: 30px auto;
    background: white;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    box-shadow: 0 0 10px #00000021;
    border-radius: 10px;
    transition: transform 0.2s ease-in-out;
}

.login_form {
    padding: 80px 0;
}

section.stepsss form input,
section.stepsss form select {
    width: 100%;
    /* height: 45px; */
    border: 1px solid #ccc;
    margin-bottom: 10px;
    -webkit-appearance: auto;
    padding: 15px;
}

section.stepsss p {
    font-size: 16px;
    font-weight: 600;
    margin: 0px 0 10px;
    color: #000;
}

section.stepsss p a {
    color: #604073;
}

section.stepsss form button {
    display: block;
    width: 100%;
    border: none;
}

section.stepsss label {
    font-size: 14px;
    color: #000;
    font-weight: 500;
}

section.stepsss .iti.iti--allow-dropdown.iti--separate-dial-code {
    width: 100%;
}

.map-go {
    margin-top: 30px;
}

.advertisement-txts form textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid #cbcbcb;
    margin-bottom: 20px;
    border-radius: 5px;
    outline: none;
    resize: none;
    height: 150px;
}

.uplod-img {
    margin-top: 75px;
}

.imgArea>input[type="file"] {
    width: 100%;
    height: 100%;
    z-index: 4;
    position: absolute;
    opacity: 0;
}

.main-car-bx ul li a {
    line-height: 1;
}

.product-inner-sec.all-section .accordion-button {
    height: 60px;
    background: #256c42;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
}

.product-inner-sec.all-section .accordion-item {
    margin-bottom: 0;
    border: 1px solid #256c42;
}

.product-inner-sec.all-section .accordion-button:focus {
    box-shadow: none;
}

.product-inner-sec.all-section .accordion-button::after {
    filter: brightness(0) invert(1);
}

.discount-txt>ul {
    margin: 0;
    display: flex;
    flex-direction: column;
}

.discount-txt>ul>li {
    align-items: center;
}

.discount-txt>ul>li>p {
    margin: 0;
}


/* loader */

.dot-spinner {
    --uib-size: 5.8rem;
    --uib-speed: .9s;
    --uib-color: #256c42;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
}

.dot-spinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.dot-spinner__dot::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
    transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
    transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
    transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
    transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
    transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
    transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
    animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
    transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
    animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {

    0%,
    100% {
        transform: scale(0);
        opacity: 0.5;
    }

    50% {
        transform: scale(1);
        opacity: 1;
    }
}

.Loading {
    position: fixed;
    top: 0;
    z-index: 99999;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
}

/* loader */

#nprogress .bar {
    background: #256c42 !important;
}

#nprogress .peg {
    box-shadow: 0 0 10px #256c42, 0 0 5px #256c42 !important;
}

#nprogress .spinner-icon {
    border-top-color: #256c42 !important;
    border-left-color: #256c42 !important;
}

.FooterLogo {
    filter: brightness(0) invert(1);
}

.price-options-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.price-option {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.price-option.selected {
    background-color: #007bff;
    color: white;
}

.error-message {
    margin-top: 10px;
    font-size: 14px;
    color: red;
}

.success-message {
    margin-top: 10px;
    font-size: 14px;
    color: green;
}

.product_list_right-upper {
    text-align: right;
}

/* Header Search */
header .input-btn>form {
    position: relative;
}

header .input-btn>form>ul.searchkey_wrpr {
    position: absolute;
    width: 100%;
    background: #fff;
    padding: 10px 15px;
    z-index: 999;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-height: 418px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0;
}

header .input-btn>form>ul.searchkey_wrpr::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    border-radius: 5px;
    /* background-color: #F5F5F5; */
}

header .input-btn>form>ul.searchkey_wrpr::-webkit-scrollbar {
    width: 5px;
    /* background-color: #F5F5F5; */
}

header .input-btn>form>ul.searchkey_wrpr::-webkit-scrollbar-thumb {
    border-radius: 5px;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3); */
    background-color: #256c42;
}

header .input-btn>form>ul.searchkey_wrpr>li>a {
    display: flex;
    align-items: center;
    gap: 10px;
}

header .input-btn>form>ul.searchkey_wrpr>li>a>div>img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid;
}

header .input-btn>form>ul.searchkey_wrpr>li {
    padding: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    margin-bottom: 10px;
}

header .input-btn>form>ul.searchkey_wrpr>li:last-child {
    margin: 0;
}

header .input-btn>form>ul.searchkey_wrpr>li>a>div>span {
    display: block;
}

header .input-btn>form>ul.searchkey_wrpr>li>a>div>span.prod_title {
    font-size: 16px;
    color: #000;
    font-family: 'Montserrat';
    font-weight: 600;
}

header .input-btn>form>ul.searchkey_wrpr>li>a>div>span.prod_prc {
    font-size: 20px;
    line-height: 1;
    margin-top: 6px;
    color: #256c42;
    font-family: 'Rajdhani';
    font-weight: 700;
}



.loader-in {
    font-size: 0;
    height: 200px;
    background: linear-gradient(89deg, #e5e5e5, #f7f7f7);
    background-size: 200%;
    background-position: left;
    animation: loadingg 2s infinite;
    border-radius: 10px;
}

@keyframes loadingg {
    0% {
        background-position: 0;
    }

    100% {
        background-position: 100%;
    }
}

.notfound:first-child {
    display: block;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Montserrat';
    padding: 20px;
    text-align: center;
}

.notfound {
    display: none;
}

.ankar li:first-child a i {
    display: none;
}

.ankar li:first-child a {
    background-color: #256c42;
    border-radius: 6px;
    color: #fff !important;
    ;
    font-family: Rajdhani;
    font-size: 13px;
    font-weight: 500;
    padding: 10px 20px;
}

.ankar li:first-child a h5 {
    color: #fff;
}

img.FeaturedPost {
    position: absolute;
    z-index: 9;
    width: 35px;
    right: 20px;
    bottom: -10px;
}

/* faq */

section.travel-ins-page-sec.pt-8.pb-8 {
    padding: 100px 0;
}

.gorrila-page-sec {
    padding: 80px 0;
}



.gorilla-content .accordion-button.collapsed {
    padding: 20px 30px;
    background-color: #256c42;
    color: white;
    font-size: 20px;
    font-family: 'Montserrat';
    line-height: 25px;
    text-transform: uppercase;
    border-radius: 7px !important;
    font-weight: 600;
}

.gorilla-content .accordion-button.collapsed:hover {
    background-color: #000000;
}

.gorilla-content .accordion-button {
    color: #256c42;
    font-size: 20px;
    padding: 20px 30px;
    line-height: 30px;
    background-color: transparent;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Montserrat';
}



.gorilla-content .gorrila-page-txt .color-red {
    color: #A60000;
    font-weight: 500;
    margin-bottom: 7px;
    margin-top: 30px;
}

.gorrila-page-txt ul {
    list-style: auto;
}

.gorrila-page-txt ul li {
    color: #000000;
    font-size: 14px;
    line-height: 23px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    margin-bottom: 15px;
    margin-left: 15px;
}

.gorrila-page-txt ul li::marker {
    color: #A60000;
}

.accordion-body.gorrila-page-txt {
    padding: 20px 30px;

}


.gorilla-content .accordion-item {
    border: 1px solid #256c42 !important;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: inset 0 0 20px 1px #256c424f;
}


.gorilla-content .accordion-button:not(.collapsed) {
    box-shadow: none;
    /* border: 1px solid; */
    border-bottom: 0;
    border-radius: 10px 10px 0 0;
}

.accordion-collapse.collapse {
    /* border: 1px solid #A60000; */
    border-top: 0;
    border-radius: 0 0 10px 10px;
    /* margin-bottom: 30px; */
}


.gorilla-content button:focus:not(:focus-visible) {
    box-shadow: none;
}

.gorilla-content .accordion-button::after {
    content: '\f067';
    font-weight: 700;
    font-family: 'Font Awesome 6 Pro';
    background-image: none;
    transform: rotate(0deg);
}

.accordion-button:not(.collapsed)::after {
    content: '\f068';
    font-weight: 700;
    font-family: 'Font Awesome 6 Pro';
}

/* blog */

.blog_box_text ul {
    gap: 40px;
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 20px 0px;
}

.blog_box_text ul li {
    display: flex;
    align-items: center;
    gap: 10px;
}

.blog_box_text ul li p {
    margin: 0;
    color: #000;
    text-transform: uppercase;
    font-weight: 500;
    padding: 0;
    border: navajowhite;
}

.blog_box_text ul li i {
    color: #fbecb2;
}

.blog_box_text p {
    padding-bottom: 20px;
    border-bottom: 1px solid #e8e8e8;
}

.blog_box_text {
    padding: 0 30px 30px 30px;
    border-radius: 10px;
}

.blog_box_image img {
    border-radius: 10px;
    height: 300px;
}

.blog_box {
    box-shadow: 1px 1px 20px 2px #00000033;
    border-radius: 10px;
    margin: 20px 0;
}

.blog_box_text a {
    text-transform: uppercase;
    border-bottom: 1px solid;
}

section.blog_sec.sec {
    padding: 100px 0px;
}

section.blog_sec.sec .heading.text_center.topbar {
    text-align: center;
}

section.blog_sec.sec .heading.text_center.topbar>h2 {
    font-size: 50px;
    color: #000;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 1.2;
}

section.blog_sec.sec .heading.text_center.topbar>p {
    width: 60%;
    margin: 0 auto;
    font-size: 16px;
    color: #000;
    font-weight: 400;
}

section.blog_sec.sec .heading.text_center.topbar {
    margin-bottom: 30px;
}

.blog_box_text a {
    color: #256c42;
    font-weight: 600;
}

.blog_box_text ul li i {
    color: #256c42;
    font-weight: 600;
}

.blog_box_image img {
    object-fit: cover;
}

/* blog detail */

.blog-list-card {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
    box-shadow: 0 0 20px 0 #0000002b;
    transition: ease-in;
    transition-duration: 0.2s;
}

.blog-list-card:hover {
    background-color: #256c42;
    transition: ease-out;
    transition-duration: 0.2s;
}

.blog-list-card-txt h5 {
    font-family: 'Montserrat';
    font-size: 16px;
    line-height: 25px;
    width: 70%;
}

.blog-list-card-user-icon-main ul li {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
}

.blog-list-card-user {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
}

.blog-list-card-user-img img {
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    object-fit: cover;
}

.blog-list-card-user-txt p {
    color: #c4c4c4;
    font-weight: 500;
    margin: 0;
}

.blog-list-card-user-txt h6 {
    font-family: 'Montserrat';
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    font-weight: 600;
}

.blog-list-card-txt-main {
    padding: 0 20px 0 0;
}

.blog-list-card-img img {
    height: 180px;
    width: 230px;
    object-fit: cover;
}

.blog-list-card:hover .blog-list-card-txt-main * {
    color: #fff;
}

.blog-list-card:hover .blog-list-card-icon a img {
    filter: brightness(100);
}

.blog-list-card-main {
    margin: 0 0 20px 0;
}

.blog-card {
    position: relative;
}

.blog-card-txt-main {
    position: absolute;
    bottom: 0;
    width: 90%;
    margin: 0 auto 30px auto;
    right: 0;
    left: 0;
}

.blog-card-txt-main * {
    color: #fff !important;
}

.blog-card-txt-main h5 {
    width: 50%;
}

.blog-card::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(76, 175, 80, 1) 5%, rgba(76, 175, 80, 0) 90%);
    transition: ease-in;
    transition-duration: 0.2s;
}

.blog-card-txt-main .blog-list-card-icon a img {
    filter: brightness(100);
}

.blog-card-img img {
    height: 380px;
    width: 100%;
    object-fit: cover;
}

.blog-card-main:hover .blog-card::before {
    background: linear-gradient(0deg, rgba(76, 175, 80, 1) 25%, rgba(76, 175, 80, 0) 100%);
    transition: ease-out;
    transition-duration: 0.2s;
}

section.blog-sec .blog-space {
    margin-top: 30px;
}

.blog-detail-card-heading-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blog-detail-search-main {
    position: relative;
    width: 80%;
    margin: 0 0 50px 0;
}

.blog-detail-card-heading {
    margin-top: 40px;
    width: 64%;
    font-size: 50px;
}

.blog-detail-search input {
    width: 100%;
    padding: 0 80px 0 20px;
    height: 60px;
    border: 1px solid #256c42;
    outline: none;
    border-radius: 5px;
}

.blog-detail-search-icon button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 5px;
    background-color: #256c42;
    border: none;
    color: #fff;
    font-size: 20px;
}

.blog-detail-search-icon {
    position: absolute;
    top: 0;
    right: 0;
}

.blog-detail-recent-main .blog-list-card-img img {
    height: 111px;
}

.blog-detail-recent-main .blog-list-card-txt h5 {
    font-size: 13px;
    line-height: 22px;
    width: 100%;
    margin: 5px 0;
}

.blog-detail-recent-main .blog-list-card-user-txt h6 {
    font-size: 9px;
    line-height: 12px;
}

.blog-detail-recent-main .blog-list-card-user-txt p {
    font-size: 9px;
    line-height: 12px;
}

.blog-detail-recent-main .blog-list-card-user {
    gap: 10px;
}

.blog-detail-recent-main .blog-list-card {
    box-shadow: 0 0 0 0 #0000;
}

.blog-detail-tags-key span {
    font-size: 12px;
    line-height: 13px;
    color: #000;
    font-family: 'Montserrat';
    text-transform: uppercase;
    padding: 0 15px;
    display: inline-flex;
    align-items: center;
    justify-content: start;
    height: 25px;
    background-color: #ededed;
    border-radius: 100px;
    transition: ease-in;
    transition-duration: 0.2s;
    margin: 5px;
    font-weight: 600;
}

.blog-detail-tags-key span:hover {
    background-color: #256c42;
    color: #fff;
    transition: ease-out;
    transition-duration: 0.2s;
}

.blog-detail-recent,
.blog-detail-tags {
    margin: 0 0 30px 0;
}

.blog-detail-card-main {
    padding: 0 40px 0 0;
}

.blog-detail-card-img img {
    height: 510px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.blog-detail-search-recent-tags-main {
    position: relative;
}

.blog-detail-search-recent-tags-main::before {
    content: '';
    position: absolute;
    top: -11%;
    left: -6%;
    border-left: 1px solid #0000001c;
    height: 1061px;
}

.blog-detail-recent-main {
    position: relative;
}

.blog-detail-recent-main::before {
    content: '';
    position: absolute;
    top: -3%;
    left: -6%;
    border-top: 1px solid #0000001c;
    width: 800px;
}

.blog-detail-tags-main {
    position: relative;
}

.blog-detail-tags-main::before {
    content: '';
    position: absolute;
    top: 22%;
    left: -6%;
    border-top: 1px solid #0000001c;
    width: 800px;
}


.blog-detail-card-heading h4 {
    font-size: 40px;
}

.blog-list-card-user-icon-main ul li p {
    margin: 0;
}

.blog-list-card-user ul li {
    display: flex;
    align-items: center;
    gap: 10px;
}

.blog-list-card-user ul li p {
    margin: 0;
}

.blog-list-card-user ul li a {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service_box1 {
    background: #ee7e4e;
    box-shadow: 1px 1px 12px 2px #ee7e4e;
}

.blog-list-card-user-icon-main a {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service_box_image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    background: #d8f7e7;
    border-radius: 10px;
}

.service_box1 .service_box_image {
    background: #f8dac0;
}

.service_box2 {
    background: #003912;
    box-shadow: 1px 1px 12px 2px #003912;
}

.service_box2 .service_box_image {
    background: #9bdeaf;
}

.context_text {
    position: relative;
    z-index: 1;
    padding: 20px 0;
}

.context_text::before {
    position: absolute;
    top: 0;
    left: -3%;
    height: 100%;
    width: 130%;
    content: '';
    background: #f1b37e;
    z-index: -1;
    border-radius: 10px;
}

.context_image {
    position: relative;
    z-index: 1;
}

section.blog-detail-sec.sec {
    padding: 100px 0px;
}

.blog-detail-card-heading h4 {
    font-family: 'Rajdhani';
    font-size: 30px;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
}

.blog-list-card-user>ul {
    margin: 0;
}

.blog-detail-card-heading {
    width: auto;
    margin: 0;
}

.blog-detail-card-heading-user {
    margin-top: 40px;
    margin-bottom: 30px;
}

.unlock a.btn-1 {
    border: 1px solid;
}

/* layout change css */

.cate_box_new .nav-underlist {
    position: relative;
}

.cate_box_new .nav-underlist>.nav-underlist_listt {
    position: absolute;
    right: -101%;
    top: 0;
    width: 100%;
    z-index: 9999;
    background: #fff;
    display: none;
}

section.Despired-car .cate_box_new {
    background: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 5px;
}

section.Despired-car .cate_box_new>.nav-underlist:last-child>h5 {
    margin: 0;
    border: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

section.Despired-car .cate_box_new>.nav-underlist {
    cursor: pointer;
}

section.Despired-car .cate_box_new>.nav-underlist>h5 {
    text-decoration: none;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 0;
    padding: 20px;
}

section.Despired-car .cate_box_new>.nav-underlist>h5>i {
    font-size: 18px;
    font-family: 'Font Awesome 6 Free';
}

section.Despired-car .cate_box_new>.nav-underlist:first-child>h5 {
    padding-top: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

section.Despired-car .cate_box_new>.nav-underlist>h5:hover {
    background: #256c422e;
    color: #000000;
}

.cate_box_new .nav-underlist>.nav-underlist_listt.show {
    display: block;
    background: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 5px;
}

.cate_box_new .nav-underlist>.nav-underlist_listt.show>a {
    padding: 15px;
    transition: none;
    margin: 0;
}

.cate_box_new .nav-underlist>.nav-underlist_listt.show>a:hover {
    background: #256c422e;
    color: #000000;
}

.cate_box_new .nav-underlist>.nav-underlist_listt.show>a:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.cate_box_new .nav-underlist>.nav-underlist_listt.show>a:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

section.Despired-car .cate_box_new>.nav-underlist>h5 {
    position: relative;
    align-items: anchor-center;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px;
}

section.Despired-car .cate_box_new>.nav-underlist>h5>img {
    width: 50px;
    height: 50px;
    background: #e3e3e3;
    border-radius: 5px;
}

section.Despired-car .cate_box_new>.nav-underlist>h5>i {
    position: absolute;
    right: 20px;
    top: 27px;
}

.menuSec .ankar ul {
    gap: 0;
    justify-content: start;
}

.menuSec .ankar li:first-child a {
    background: #1a4a2e;
    border-radius: 0;
}

.menuSec .ankar li:first-child a>h5 {
    color: #fff;
    font-size: 20px;
    height: 35px;
    display: flex;
    align-items: center;
}

.menuSec .row {
    align-items: center;
}

.menuSec {
    padding: 5px 0px;
}

header .input-btn>form>ul.searchkey_wrpr>li {
    width: 100%;
}